import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { Form as ElForm, Input } from 'element-ui';
import { authService } from '@/modules/account/services/authService';
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';
import { validateItalianFiscalCode, validatePassword } from '@/utils/validate';
import settings from "@/settings";
import { accountServices } from '../services/accountService';


@Component({
  name: 'Login'
})
export default class extends Vue {
  private loginForm: account.login = {
    userName: null,
    password: null
  };

  giornalista: boolean = true;

  get loginRules() {
    return {
      userName: [
        {
          required: true,
          message: `${this.$t('login.required.username')}`,
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('login.required.password')}`));
            } else if (!validatePassword(value)) {
              callback(new Error(`${this.$t('login.required.passwordPattern')}`));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
      ]
    }
  }

  private passwordType = 'password';
  private loading = false;
  private showDialog = false;
  private capsTooltip = false;
  private redirect?: string;
  private otherQuery: Dictionary<string> = {};
  private spidProvidersVisible = false;
  private spidProviders: account.spidProvider[] = [];

  @Watch('$route', { immediate: true })
  private onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }
  
  private frontendVersion: string = null;
  private serverVersion: string = null;
  async mounted() {
    if (this.loginForm.userName === '') {
      (this.$refs.userName as Input).focus();
    } else if (this.loginForm.password === '') {
      (this.$refs.password as Input).focus();
    }

    try {
      this.spidProviders = await authService.GetSPIDProviders();
    }
    catch { console.warn("No SPID provider found")}

    (await fetch('/version.txt', {
      mode: 'no-cors' // 'cors' by default
    })).text().then((t) => {
      this.frontendVersion = t;
    }).catch(() => console.warn("Versione FrontEnd non trovata"));

    (await fetch(`${settings.baseUrl}/version.txt`, {
      mode: 'no-cors' // 'cors' by default
    })).text().then((t) => {
      this.serverVersion = t;
    }).catch(() => console.warn("Versione FrontEnd non trovata"));
  }


  idProviderEnabled(provider: string) {
    return this.spidProviders.some(p => p.entityName == provider);
  }

  doSpidLogin(provider: string) {
    authService.DoSpidLogin(provider);
  }

  private checkCapslock(e: KeyboardEvent) {
    const { key } = e;
    this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
  }

  private showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = '';
    } else {
      this.passwordType = 'password';
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus();
    });
  }

  private handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        authService
          .Login(this.loginForm)
          .then((result) => {
            this.$router
              .push({
                path: this.redirect || '/',
                query: this.otherQuery
              })
            this.loading = false;
          })
          .catch(e => {
            let error = `${this.$t('login.error')}`;
            if (e.response && e.response.data) {
              error = e.response.data.exceptionMessage || e.response.data.message || error;
            }
            this.loading = false;
            this.$msgbox({
              title: `Attenzione!`,
              message: error,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'error'
            });
          });
      }
    });
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur];
      }
      return acc;
    }, {} as Dictionary<string>);
  }

  recoverPassword() {
    this.$router.push({ path: '/recoverPassword' });
  }

  register() {
    this.$prompt(`Inserisci il Codice Fiscale per verificare la presenza dell'anagrafica nell'archivio dei giornalisti del CNOG`, `Registrati inserendo il Codice Fiscale`, {
      confirmButtonText: `Avanti`,
      cancelButtonText: 'Annulla',
      inputValidator: this.validateFiscalCode,
      inputPlaceholder: 'Inserisci il Codice Fiscale',
    })
      .then(async ({ value }: any) => {
        const accountExsist = await accountServices.CheckUsernameExist(value);
        if(accountExsist) {
          this.$alert(`Il Codice Fiscale inserito <i>${value}</i> è già registrato a "formazionegiornalisti.it"`, 'Attenzione!', {
            confirmButtonText: 'Ok',
            type: "warning",
            dangerouslyUseHTMLString: true
          });
          return;
        }

        const giornalista = await anagraficaService.DetailByCodiceFiscale(value);
        if (giornalista)
          this.$router.push({ name: 'register', params: { codiceFiscale: value } });
        else
          this.$alert(`Il Codice Fiscale inserito <i>${value}</i> non è presente nell'archivio dei giornalisti del CNOG.<br>
          Contatta il tuo ordine Regionale per poter completare correttamente la registrazione.`, 'Attenzione!', {
            confirmButtonText: 'Ok',
            type: "warning",
            dangerouslyUseHTMLString: true
          });
      })
      .catch(() => { });
  }

  private validateFiscalCode(value): boolean | string {
    if (!value || value.replace(/\s+/, "").length <= 0)
      return `Il campo 'Codice Fiscale' non può essere vuoto`;
    if (!validateItalianFiscalCode(value))
      return `Il campo 'Codice Fiscale' è errato`;
    return true
  }

  toggleSpidVisible() {
    if (this.spidProvidersVisible) {
      this.spidProvidersVisible = false;
      return;
    }

    // var rootList = this.$refs.providersList as HTMLUListElement;
    // var idpList = Array.from(rootList.getElementsByClassName("spid-idp-button-link"));
    // var lnkList = Array.from(rootList.getElementsByClassName("spid-idp-support-link"));
    // while (idpList.length) {
    //   rootList.append(idpList.splice(Math.floor(Math.random() * idpList.length), 1)[0]);
    // }

    // rootList.append(...lnkList);

    var ul = this.$refs.spidProviders as HTMLUListElement;
    for (var i = Array.from(ul.children).filter(f => f.className === 'spid-idp-button-link').length; i >= 0; i--) {
      ul.prepend(Array.from(ul.children).filter(f => f.className === 'spid-idp-button-link')[Math.random() * i | 0]);
    }
    this.spidProvidersVisible = true;
  }
}
